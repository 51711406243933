<template>
  <div>
    <v-scale-transition>
      <v-app-bar app dark color="blue-grey darken-4">
        <div class="d-flex align-center">
          <v-img
              alt="Imagined Earth"
              class="shrink ml-2"
              contain
              :src="require('@/assets/top-logo.png')"
          />
        </div>

        <v-spacer></v-spacer>

        <v-menu
            v-if="!$store.getters.isGuest"
            transition="scroll-y-transition"
            offset-y
            open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                text
                dark
            >
              <v-icon class="mr-2">mdi-account-circle</v-icon>
              Account
            </v-btn>
          </template>
          <v-list>
            <v-list-item link to="/profile">
              <v-list-item-title>My profile</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logout()">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </v-scale-transition>

    <v-overlay :value="flagLoggingOut">
      <v-progress-circular
          indeterminate
          size="64"
          :width="1"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from 'axios'

export default ({
  name: "AppBar",
  data: () => ({
    flagLoggingOut: false,
  }),
  methods: {
    logout() {
      this.flagLoggingOut = true;
      let url = `${process.env.VUE_APP_BASE_API}auth/logout`

      axios
          .post(url, {}, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwt}`
            }
          })
          .then(() => {
            this.executeAfterLogout()
          })
          .catch(() => {
            this.executeAfterLogout()
          });
    },
    executeAfterLogout() {
      this.flagLoggingOut = false
      this.$store.commit("logout")
      this.$router.push("/login")
    }
  }
});
</script>
