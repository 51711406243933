import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Page404 from '@/views/pages/Page404'
import Page403 from '@/views/pages/Page403'
import BarcodeScanner from '@/views/vrvm/BarcodeScanner'
import Dashboard from '@/views/Dashboard'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "Home",
        meta: {
            requiresAuth: true
        },
        component: Dashboard,
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            requiresAuth: false
        },
        component: Login
    },
    {
        path: "/vrvm/scanner/scan",
        name: "BarcodeScanner",
        meta: {
            requiresAuth: true
        },
        component: BarcodeScanner
    },
    {
        path: "/vrvm/incomplete-products/view",
        name: "IncompleteProducts",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import("@/views/vrvm/IncompleteProducts/Index.vue")
    },
    {
        path: "/vrvm/incomplete-products/fix",
        name: "FixIncompleteProduct",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/vrvm/IncompleteProducts/FixProduct.vue"),
        props: true,
    },
    {
        path: "/waste-partners/waste-partners/all",
        name: "WastePartners",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/waste-partners/Index"),
    },
    {
        path: "/waste-partners/waste-partners/manage",
        name: "WastePartnerFormView",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/waste-partners/Form"),
        props: true,
    },
    {
        path: "/waste-partners/price-mixes/manage/:waste_partner_id",
        name: "WastePartnerPriceMixes",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/waste-partners/PriceMixes"),
        props: true,
    },
    {
        path: "/users/staff",
        name: "ManageStaff",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/users/Staff"),
    },
    {
        path: "/profile",
        name: "Profile",
        meta: {
            requiresAuth: true
        },
        component: Dashboard
    },
    {
        path: "/404",
        name: "NotFound",
        meta: {
            requiresAuth: false
        },
        component: Page404
    },
    {
        path: "/403",
        name: "NotAllowed",
        meta: {
            requiresAuth: false
        },
        component: Page403
    },
    {
        path: "/no-rvm",
        name: "NoRVM",
        meta: {
            requiresAuth: false
        },
        component: () => import("@/views/pages/PageNoRVM"),
    },
    {
        path: "*",
        name: "not-found",
        meta: {
            requiresAuth: false
        },
        component: Page404
    }
]

const router = new VueRouter({
    routes
})

const PUBLIC_ROUTES = [
    '/',
    '/dashboard',
    '/login',
    '/403',
    '/404',
    '/no-rvm',
]

router.beforeEach((to, from, next) => {
    if (accessDenied(to.path)) {
        next("/403");
    }
    else if (to.meta.requiresAuth) {
        if (store.getters.isGuest) {
            //store.commit('setAuthRouteRequested', to.path)
            next("/login");
        } else {
            next();
        }
    } else {
        if (to.meta.requiresGuest) {
            if (store.getters.isGuest) {
                next();
            } else {
                next("/dashboard");
            }
        } else {
            next();
        }
    }
});


function accessDenied(path) {
    return !(PUBLIC_ROUTES.includes(path) || requestedPathContainsAnyPermissionPath(path))
}

function requestedPathContainsAnyPermissionPath(path) {
    let permissionIndex = store.getters.getFlatPermissions.findIndex(permission => path.includes(permission))

    return (permissionIndex >= 0)
}

export default router
