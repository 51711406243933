import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import Breadcrumbs from "@/components/common/Breadcrumbs";
import Axios from '@/components/common/Axios'
import Snackbar from '@/components/common/Snackbar'
import YesNoDlg from '@/components/common/YesNoDlg'

Vue.config.productionTip = false

Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('Axios', Axios)
Vue.component('Snackbar', Snackbar)
Vue.component('YesNoDlg', YesNoDlg)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
