<template>
  <v-app>
    <template v-if="!$store.getters.isGuest">
      <app-bar/>
      <side-bar/>
    </template>

    <v-main wrap>
      <v-layout>
        <v-flex>
          <transition
              mode="out-in"
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
          >
            <router-view></router-view>
          </transition>
        </v-flex>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/layout/AppBar'
import SideBar from '@/components/layout/Sidebar'
import 'animate.css'

export default ({
  name: "App",

  data: () => ({
    drawer: false,
    mini: false,
    sidebarItems: [
      {title: "Home", icon: "mdi-home-city", to: "/"},
      {title: "Scan items", icon: "mdi-barcode", to: "/scanner"}
    ]
  }),
  methods: {
    logout() {
      this.$store.commit("logout");
      //send post request
      this.$router.push("/");
    }
  },
  components: {AppBar, SideBar}
});
</script>

<style>
.animate__animated.animate__fadeIn, .animate__animated.animate__fadeOut {
  --animate-duration: 0.3s;
}
</style>
