import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  key: 'tide-app',
  storage: localStorage
})
export default new Vuex.Store({
  state: {
    user: {
      jwt: null,
      avatar: null,
      fullName: null,
      modules: [],
      flatPermissions: [],
      rvms: [],
    },
    currentRvm: null,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setCurrentRVM(state, rvm) {
      state.currentRvm = rvm;
    },
    logout(state) {
      state.user = {
        jwt: null,
        avatar: null,
        fullName: null,
        modules: [],
        flatPermissions: [],
        rvms: [],
      };
      state.currentRvm = null
    },
  },

  actions: {},

  modules: {},

  getters: {
    getJwt(state) {
      return state.user.jwt;
    },
    getAvatar(state) {
      return state.user.avatar;
    },
    isGuest(state) {
      return !state.user.jwt;
    },
    getFullName(state) {
      return state.user.fullName;
    },
    getModules(state) {
      return state.user.modules;
    },
    getFlatPermissions(state) {
      return state.user.flatPermissions;
    },
    getRVMs(state) {
      return state.user.rvms;
    },
    getCurrentRVM(state) {
      return state.currentRvm;
    },
  },

  plugins: [vuexLocal.plugin],
});
