<template>
  <div>
    <breadcrumbs :items="breadcrumbs"/>

    <div class="ml-6">
      <v-row>
        <v-col cols="12">
          <h4>Welcome to your dashboard!</h4><br/>
          You can put some awesome content here :)
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "Dashboard",

  data: () => ({
    breadcrumbs: [
      { text: "Dashboard", disabled: true, }
    ]
  }),
});
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
