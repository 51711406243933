<template>
  <login-component/>
</template>

<script>
import Vue from "vue";
import LoginComponent from "@/components/Login.vue";

export default Vue.extend({
  name: "Login",
  components: {
    LoginComponent,
  },
});
</script>
