<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" sm="8" offset-sm="2">
        <h1 class="float-left display-3 mr-4">
          <v-img v-cloak width="150" :src="require('@/assets/403.png')" />
        </h1>
        <h4 class="pt-3">Oops! Access denied.</h4>
        <p class="text-muted">You are not allowed to access the requested resource.</p>
        <p class="text-center">
          <v-btn color="success" to="/">Go to homepage</v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Page403"
});
</script>
