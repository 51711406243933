<template>
  <div>
    <snackbar
        :snackbar="snackbar"
        :color="operationMessageType"
        :message="operationMessage"
    />

    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
          :width="1"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      snackbar: false,
      overlay: false,
      operationMessage: null,
      operationMessageType: null,
      requestInfo: {}
    };
  },
  methods: {
    submit(config) {
      this.requestInfo = config;
      this.overlay = config.overlay ?? false
      let endpointSlug = this.requestInfo.url
      this.requestInfo.url = `${process.env.VUE_APP_BASE_API}${encodeURI(this.requestInfo.url)}`;

      if (!this.requestInfo.headers) {
        this.requestInfo.headers = {};
      }

      this.requestInfo.headers[
          "Authorization"
          ] = `Bearer ${this.$store.getters.getJwt}`;

      this.requestInfo.headers["Content-Type"] = "application/json";
      this.requestInfo.headers["Access-Control-Allow-Credentials"] = "*";

      switch (this.requestInfo.method) {
        case "form":
        case "post":
          this.post(this).then(result => {
            this.executeAfterRequestActions(result, endpointSlug, this.requestInfo.method)
          });
          break;
        case "put":
          this.put(this).then(result => {
            this.executeAfterRequestActions(result, endpointSlug, this.requestInfo.method)
          });
          break;
        case "delete":
          this.delete(this).then(result => {
            this.executeAfterRequestActions(result, endpointSlug, this.requestInfo.method)
          });
          break;
        default:
          this.get(this).then(result => {
            this.executeAfterRequestActions(result, endpointSlug, this.requestInfo.method)
          });
          break;
      }
    },

    get(that) {
      return new Promise((resolve, reject) => {
        axios
            .get(this.requestInfo.url, this.requestInfo)
            .then(function (response) {
              let result = {code: 200, response: response.data}
              resolve({result})
            })
            .catch(function (error) {
              let result = that.returnError(error)
              resolve(result)
            });
      });
    },

    post(that) {
      return new Promise((resolve, reject) => {
        axios
            .post(this.requestInfo.url, this.requestInfo.params, {
              headers: this.requestInfo.headers
            })
            .then(function (response) {
              let result = {code: 200, response: response.data}
              resolve({result})
            })
            .catch(function (error) {
              let result = that.returnError(error)
              resolve(result)
            });
      });
    },

    put(that) {
      return new Promise((resolve, reject) => {
        axios
            .put(this.requestInfo.url, this.requestInfo.params, {
              headers: this.requestInfo.headers
            })
            .then(function (response) {
              let result = {code: 200, response: response.data}
              resolve({result})
            })
            .catch(function (error) {
              let result = that.returnError(error)
              resolve(result)
            });
      });
    },

    delete(that) {
      return new Promise((resolve, reject) => {
        axios
            .delete(this.requestInfo.url, {
              headers: this.requestInfo.headers,
              data: this.requestInfo.params
            })
            .then(function (response) {
              let result = {code: 200, response: response.data}
              resolve({result})
            })
            .catch(function (error) {
              let result = that.returnError(error)
              resolve(result)
            });
      });
    },

    returnError(error) {
      return {code: error.response.status, response: error.response.data};
    },

    executeAfterRequestActions(result, endpointSlug, verb) {
      this.overlay = false

      if (result.code === 401) {
        alert('Credentials expired. Please, log back in.')
        this.$store.commit('logout')
        this.$router.push('/login')
      } else {
        if (this.requestInfo.snackbar) {
          this.showSnackbar(
              'Operation finished',
              'success',
          )
        }

        this.$emit("finish", {data: result, url: endpointSlug, verb: verb})
      }
    },

    showSnackbar(operationMessage, operationMessageType) {
      this.snackbar = this.requestInfo.snackbar
      this.operationMessage = operationMessage;
      this.operationMessageType = operationMessageType;
    }
  }
}
;
</script>
