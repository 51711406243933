<template>
  <barcode-scanner-component/>
</template>

<script>
import BarcodeScannerComponent from '../../components/vrvm/BarcodeScanner'

export default ({
  name: "BarcodeScanner",

  components: {BarcodeScannerComponent},
});
</script>
