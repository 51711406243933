<template>
  <v-breadcrumbs :items="breadcrumbs">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default ({
  name: "BreadcrumbsComponent",
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/#/dashboard"
      }
   ]
  }),
  props: ['items'],
  mounted() {
    this.breadcrumbs = this.breadcrumbs.concat(this.items)
  }
});
</script>
