<template>
  <v-main class="hero pt-10">
    <v-row class="mt-15">
      <v-col cols="6" offset="3">
        <v-card class="text-center">
          <v-responsive>
            <img
                class="img-responsive"
                :src="require('@/assets/ie_logo_fullwidth.jpg')"
                alt="Logo"
            />
          </v-responsive>
          <v-card-text class="blue-grey lighten-5">
            <div class="mb-2 text-subtitle-1">
              <p>
                Welcome to
                <span class="font-weight-bold">Imagined Earth</span> Platform
              </p>
            </div>
            <v-form v-model="validForm">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="10" md="6">
                    <v-text-field
                        v-model="email"
                        :rules="[rules.required, rules.email]"
                        label="Email"
                        append-icon="mdi-email"
                        autofocus
                        outlined
                        dense
                        @keyup.enter="login()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10" md="6">
                    <v-text-field
                        v-model="password"
                        label="Password"
                        outlined
                        dense
                        :append-icon="
                        flagShowPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                        :rules="[rules.required, rules.min]"
                        :type="flagShowPassword ? 'text' : 'password'"
                        @click:append="flagShowPassword = !flagShowPassword"
                        @keyup.enter="login()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12">
                    <div class="float-right">
                      <a class="mr-5">Forgot your password?</a>
                      <v-btn
                          @click="login()"
                          color="success"
                          :disabled="!validForm || sendingLoginRequest"
                      >
                        <v-icon size="18" class="mr-1">{{
                            compBtnLoginIcon
                          }}
                        </v-icon>
                        Login
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        top
        right
        :color="operationResultColor"
        elevation="24"
    >
      {{ operationResultMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="sendingLoginRequest">
      <v-progress-circular
          indeterminate
          size="64"
          :width="1"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import axios from 'axios'

export default ({
  name: "Login",

  data: () => ({
    operationResultMessage: "",
    operationResultColor: "",
    snackbar: false,
    sendingLoginRequest: false,
    validForm: false,
    email: "",
    password: "",
    flagShowPassword: false,
    rules: {
      required: (value) => !!value || "This field is required",
      min: (value) =>
          (value && value.length > 2) || `At least 3 characters`,
      email: (value) => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email format"
      }
    }
  }),
  computed: {
    compBtnLoginIcon: function () {
      return this.validForm ? "mdi-check" : "mdi-alert-circle-outline"
    }
  },
  methods: {
    login() {
      if (this.email && this.password) {
        this.sendingLoginRequest = true;
        let url = `${process.env.VUE_APP_BASE_API}auth/login`

        axios
            .post(url, {
              email: this.email.trim(),
              password: this.password.trim()
            })
            .then((response) => {
              this.sendingLoginRequest = false

              this.$store.commit("setUser", response.data)
              this.$router.push("/dashboard")
            })
            .catch((error) => {
              this.sendingLoginRequest = false

              this.operationResultMessage = error.response.data ?? error
              this.operationResultColor = 'error'
              this.snackbar = true
            });
      }
    }
  }
});
</script>

<style scoped>
.hero {
  background: url("../assets/login_background.jpeg");
  background-size: cover;
  height: 100vh;
}
</style>
