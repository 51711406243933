<template>
    <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        dark
        fixed
        app
        color="blue-grey darken-3"
    >
      <v-list-item class="px-2 mt-16">
        <v-list-item-avatar>
          <v-img :src="$store.getters.getAvatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>
          <span class="subtitle-1">{{ $store.getters.getFullName }}</span>
        </v-list-item-title>

        <v-btn
            icon
            @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense>
        <div v-for="(module, i) in modules" :key="`module-${i}`">

          <v-list-item
              v-if="!module.permissions"
              :to="module.route"
          >
            <v-list-item-icon>
              <v-icon>{{ module.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="module.name"/>
          </v-list-item>

          <v-list-group
              v-else
              :key="module.name"
              no-action
              :prepend-icon="module.icon"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ module.name }}</v-list-item-title>
            </template>

            <v-list-item
                v-for="permission in module.permissions"
                :to="permission.route"
                :key="`nav-${permission.route}`"
            >
              <v-list-item-icon class="ml-2">
                <v-icon>{{ permission.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ permission.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
</template>

<script>

export default ({
  name: "Sidebar",

  data: () => ({
    modules: [],
    drawer: false,
    mini: false
  }),
  mounted() {
    this.modules = this.$store.getters.getModules;
  }
});
</script>

<style scoped>
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding: 0 4px;
}
</style>
