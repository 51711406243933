<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" offset-sm="3">
        <h1 class="float-left display-3 mr-4">
          <v-img width="150" :src="require('@/assets/404.png')" />
        </h1>
        <h4 class="pt-3">Oops! You're lost.</h4>
        <p class="text-muted">The page you are looking for was not found.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Page404"
});
</script>
