<template>
  <v-snackbar
      v-model="show"
      :color="color"
      dismissible
      timeout="3000"
      top
      right
  >
    {{ message }}
  </v-snackbar>
</template>

<script>

export default {
  name: 'Snackbar',
  props: ['snackbar', 'color', 'message', ],
  computed: {
    show() {
      return this.snackbar
    }
  }
}
</script>
