<template>
  <v-dialog v-model="visible" :width="width" persistent>
    <v-card>
      <v-card-title
          class="headline blue-grey white--text"
          primary-title
      >{{ title }}
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <p>{{ question }}</p>
              <p>Are you sure you want to proceed?</p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            small
            :disabled="processing"
            color="success"
            @click="$emit('yes')"
        >
          <v-progress-circular
              v-if="processing"
              :size="15"
              :width="1"
              indeterminate
          >
          </v-progress-circular>
          <span class="ml-2">Yes</span>
        </v-btn>

        <v-btn small color="error" @click="$emit('no')">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["visible", "width", "title", "question", "chip", "processing"]
};
</script>
